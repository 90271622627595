<template>
  <div class="empty">
    <img src="@/assets/images/empty.png" alt="" />
    <p>{{ emptyStr }}</p>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    emptyStr: {
      type: String,
      default: () => {
        return '';
      },
    },
  },
};
</script>

<style scoped lang="less">
.empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  img {
    width: 5.24rem;
    height: 3.06rem;
    margin: 0 auto 0.36rem;
  }

  p {
    font-size: 0.28rem;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: #999999;
    opacity: 0.5;
  }
}
</style>
