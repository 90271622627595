<template>
  <div class="my_assessment">
    <navigationBar :showBack="showBack">我的测评</navigationBar>
    <evaluationItem @getEmotyShow="getEmotyShow" @handelStart="handelStart"></evaluationItem>
    <empty :emptyStr="emptyStr" v-show="emptyShow"></empty>
    <van-popup v-model="showNotice"
      ><notice
        v-if="showNotice"
        :finished="finished"
        :evaluationId="evaluationId"
      ></notice
    ></van-popup>
  </div>
</template>

<script type="text/ecmascript-6">
import navigationBar from "@/components/navigationBar/navigationBar";
import evaluationItem from "@/components/evaluationItem/evaluationItem";
import empty from "@/components/empty/empty";
import notice from "@/components/notice/notice";
export default {
  data() {
    return {
      emptyStr: "暂无测评记录",
      emptyShow: false,
      showBack: true,
      showNotice: false,
      finished: 0,
      evaluationId: 0,
    };
  },
  components: {
    navigationBar,
    evaluationItem,
    empty,
    notice,
  },

  methods: {
    getEmotyShow(data) {
      this.emptyShow = data;
    },

    // 子组件传值
    handelStart(finished, id) {
      this.finished = finished;
      this.evaluationId = id;
      this.showNotice = true;
    },
  },
};
</script>

<style scoped lang="less">
.my_assessment {
  width: 100%;
  min-height: calc(100vh - 0.88rem);
  background-color: #f5f5f5;
  margin-top: 0.88rem;
  padding: 0 0 0.98rem 0;
}

/deep/ .van-popup {
  border-radius: 0.12rem;
  background-color: transparent;
}
</style>
