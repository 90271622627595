<template>
  <div class="evaluation_item">
    <div class="item" v-for="(item, index) of dataList" :key="index">
      <div class="img_box" @click="handelGoDetail(item.code)">
        <img :src="item.xcxImageUrl" alt="" />
      </div>
      <div class="status_box" @click="handelGoDetail(item.code)">
        <h3>{{ item.name }}</h3>
        <span>{{ item.finishedNum }}人已测</span>
      </div>
      <!-- <div
        class="status_btn"
        v-if="item.finished == 0"
        @click="handelStart(item.finished, item.code, item.name)"
      >
        开始测评
      </div>
      <div
        class="status_btn report"
        v-if="item.finished == 1"
        @click="handelEvaluationReport(item.finished, item.code, item.name)"
      >
        测评报告
      </div>
      <div
        class="status_btn begin"
        v-if="item.finished == 2"
        @click="handelStart(item.finished, item.code, item.name)"
      >
        继续测评
      </div> -->
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { getMyEvaluation } from "@/request/api";
export default {
  data() {
    return {
      loginUser: {},
      dataList: [],
    };
  },
  components: {},
  created() {
    this.loginUser = JSON.parse(localStorage.getItem("loginUser"));
  },
  mounted() {
    this.getMyEvaluation(this.loginUser.id);
  },

  methods: {
    getMyEvaluation(id) {
      getMyEvaluation(id).then((res) => {
        this.dataList = res.data;

        if (this.dataList.length == 0) {
          this.$emit("getEmotyShow", true);
        } else {
          this.$emit("getEmotyShow", false);
        }
      });
    },

    // 开始测评
    handelStart(finished, id, name) {
      // this.$emit("handelStart", finished, id);
      this.$router.push({ path: "/notice", query: { finished: finished, evaluationId: id } });
      localStorage.setItem("name", JSON.stringify(name));
    },

    // 测评报告
    handelEvaluationReport(finished, id, name) {
      this.$router.push({ path: "/report", query: { code: id } });
      // if(id == '14111400' || id == '11130000' || id == '11110000') {
      //    this.$router.push({ path: "/report", query: { code: id } });
      // } else {
      //   this.$router.push({ path: "/EvaluationReport", query: { code: id } });
      // }
      localStorage.setItem("name", JSON.stringify(name));
    },

    // 去测评详情
    handelGoDetail(id) {
      this.$router.push({ path: "/mbti", query: { id: id } });
    },
  },
};
</script>

<style scoped lang="less">
.evaluation_item {
  padding: 0.2rem 0.2rem 0 0.2rem;

  .item {
    display: flex;
    margin: 0 0 0.2rem 0;
    background: #ffffff;
    border-radius: 0.08rem;
    padding: 0.2rem;
    position: relative;

    .img_box {
      margin: 0 0.2rem 0 0;
      img {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 0.08rem;
      }
    }
    .status_box {
      h3 {
        font-size: 0.3rem;
        font-family: "PingFangSC-Regular";
        font-weight: 500;
        color: #333333;
        line-height: 0.38rem;
        margin-bottom: 0.29rem;
      }

      span {
        font-size: 0.26rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #999999;
        line-height: 0.38rem;
      }
    }
    .status_btn {
      font-size: 0.26rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #ffffff;
      padding: 0.18rem 0.28rem;
      background: #0067b0;
      border-radius: 0.5rem;
      position: absolute;
      right: 0.2rem;
      bottom: 0.2rem;
    }

    .report {
      background: #f2961c;
    }

    .begin {
      background: #00a6e3;
    }
  }

  .item:last-child {
    margin: 0;
  }
}
</style>
